<template>
  <div class="static-main-page">
    <div class="static-main-page__max-width">
      <div class="w-100">
        <div v-html="text" class="m-20"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    text: `
    
    <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Terms & Conditions</h3>
    <p class="p2"><br></p>
    <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Interruptions and Omissions
                                in Service</h3>
                            <p style="font-family:Poppins-Light, sans-serif">While we try to ensure that the standard of
                                this website remains high and to maintain the
                                continuity of it, but the internet is not an inherently stable medium, and errors,
                                omissions, interruptions of service and delays may occur at any time. We do not accept
                                any liability arising from any such errors, omissions, interruptions or delays or any
                                ongoing obligation or responsibility to operate this website (or any particular part of
                                it) or to provide the service offered on this website. We may vary the specification of
                                this site from time to time without notice.</p>
                        </div>
                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Information on this Site</h3>
                            <p style="font-family:Poppins-Light, sans-serif">While we make every effort to ensure that
                                the information on this site is accurate and
                                complete, some of the information is supplied to us by third parties and we are not able
                                to check the accuracy or completeness of that information. We do not accept any
                                liability arising from any inaccuracy or omission in any of the information on this site
                                or any liability in respect of information on this site supplied by you, any other site
                                user or any other person.</p>
                        </div>
                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Your Use of this Site</h3>
                            <p style="font-family:Poppins-Light, sans-serif">You may only use this site for lawful
                                purposes when seeking work or help with your career
                                or when recruiting staff. You must not under any circumstances seek to undermine the
                                security of the site or any information submitted to or available through it.</p>
                            <p style="font-family:Poppins-Light, sans-serif">In particular, but without limitation, you
                                must not seek to access, alter or delete any
                                information to which you do not have authorized access, seek to overload the system via
                                spamming or flooding, take any action or use any device, routine or software to crash,
                                delay, damage or otherwise interfere with the operation of this site or attempt to
                                decipher, disassemble or modify any of the software, coding or information comprised in
                                the site. You are solely responsible for any information submitted by you to this
                                site.</p>
                            <p style="font-family:Poppins-Light, sans-serif">You are responsible for ensuring that all
                                information supplied by you is true, accurate,
                                up-to-date and not misleading or likely to mislead or deceive and that it is not
                                discriminatory, obscene, offensive, defamatory or otherwise illegal, unlawful or in
                                breach of any applicable legislation, regulations, guidelines or codes of practice or
                                the copyright, trade mark or other intellectual property rights of any person in any
                                jurisdiction.</p>
                            <p style="font-family:Poppins-Light, sans-serif">You are also responsible for ensuring that
                                all information, data and files are free of
                                viruses or other routines or engines that may damage or interfere with any system or
                                data prior to being submitted to this site. We reserve the right to remove any
                                information supplied by you from the website at our sole discretion, at any time and for
                                any reason without being required to give any explanation.</p>

                        </div>
                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Information Submitted by You</h3>
                            <p style="font-family:Poppins-Light, sans-serif">We will use information supplied by you
                                (including, without limitation, sensitive
                                personal data and or in the case of employers submitting vacancy details all vacancy
                                details and employer information) to aid the recruitment process and associated
                                administrative functions.</p>
                            <p style="font-family:Poppins-Light, sans-serif">This involves us, amongst other things,
                                processing and storing information (including,
                                without limitation, sensitive personal data and or all vacancy and employer details and
                                information) and passing or making available online such information to prospective
                                employers, candidates and or clients; information about vacancies and placements will be
                                passed to candidates and may be posted directly onto our website, other third party
                                websites and any other recruitment advertising channel.</p>

                            <p style="font-family:Poppins-Light, sans-serif">We may collect and aggregate data from the
                                information supplied by you to help us to
                                understand our users as a group so that we can provide you with a better service. We may
                                also share aggregate information with selected third parties, without disclosing
                                individual names or identifying information.</p>

                            <p style="font-family:Poppins-Light, sans-serif">You consent to us using information
                                provided by you (including, without limitation,
                                sensitive personal data) in each of these ways.</p>

                            <p style="font-family:Poppins-Light, sans-serif">By agreeing to these Conditions of Use, you
                                agree that Medical Job may pass relevant
                                personnel records to our associated companies.
                            </p>
                            <p style="font-family:Poppins-Light, sans-serif">
                                We will process any data which you provide in completing the online registration or
                                application forms and any further
                                forms, assessments or personal details which you complete or provide to us when using
                                this site in accordance with Lebanon data protection best practice.

                            </p>
                            <p style="font-family:Poppins-Light, sans-serif">
                                Your records will continue to be handled in accordance with data protection good
                                practice and your
                                information will be held in a safe, confidential and secure environment although you
                                might no longer have rights under Lebanon data protection law. If you object to your
                                information being transferred or used in this way please do not register.</p>

                        </div>

                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Content Rights</h3>
                            <p style="font-family:Poppins-Light, sans-serif">The rights in material on this site are
                                protected by international copyright, software
                                and trademark laws and you agree to use this site in a way which does not infringe these
                                rights. You may copy material on this site for your own private or domestic purposes,
                                but no copying for any commercial or business use is permitted.</p>
                        </div>

                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Links to other Sites</h3>
                            <p style="font-family:Poppins-Light, sans-serif">On this site you will be offered automatic
                                links to other sites which we hope will be of
                                interest to you. We do not accept any responsibility for or liability in respect of the
                                content of those sites, the owners of which do not necessarily have any connection,
                                commercial or otherwise, with us. Using automatic links to gain access to such sites is
                                entirely at your own risk.</p>
                        </div>


                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Trading Terms</h3>
                            <p style="font-family:Poppins-Light, sans-serif">Each assignment or placement made through
                                Medical Job will be subject to our standard
                                contract. Unless we have a different written agreement with you, our standard contract
                                will apply. All prospective workers, employers and clients for whom we arrange
                                assignments or placements will be provided with a copy of the contract applicable to
                                them at or following registration.</p>
                        </div>

                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Liability</h3>
                            <p style="font-family:Poppins-Light, sans-serif">We accept no liability for any loss
                                (whether for loss of business, revenue or profits,
                                wasted expenditure, corruption or destruction of data or for any other direct, indirect
                                or consequential loss whatsoever) arising from your use of the site and we hereby
                                exclude any such liability, whether in contract, tort (including for negligence) or
                                otherwise. We hereby exclude all representations, warranties and conditions relating to
                                this website and your use of it to the maximum extent permitted by law.</p>

                            <p style="font-family:Poppins-Light, sans-serif">You agree to indemnify us and keep us
                                indemnified against all costs, expenses, claims,
                                losses, liabilities or proceedings arising from use or misuse by you of this site.</p>


                            <p style="font-family:Poppins-Light, sans-serif">You must notify us immediately if anyone
                                makes or threatens to make any claim against you
                                relating to your use of this site.</p>
                        </div>

                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Choice of Law and Jurisdiction</h3>
                            <p style="font-family:Poppins-Light, sans-serif">The use of this website and any agreements
                                entered into through this website are to be
                                governed by and construed in accordance with Lebanese law. Your use of this site
                                constitutes your submission to the jurisdiction of the courts of Lebanon to settle any
                                dispute arising out of or in connection with the use of this website or any agreement
                                made through this website.</p>

                            <p style="font-family:Poppins-Light, sans-serif">Some of the goods or services offered
                                through this website may not be lawful or may
                                otherwise not be permitted in certain countries outside Lebanon. If you attempt to
                                order, receive, purchase or otherwise benefit from any such goods or services, we do not
                                accept any liability for any losses suffered by you in using this website which you
                                would not have suffered had you been accessing this website as a Lebanese resident
                                (including as a result of us being prevented from dealing with any application or
                                enquiry by any law, regulation or other ruling applicable in any country).
                            </p>
                        </div>

                        <div class="terms-text">
                            <h3 style="font-family:Poppins-Bold, sans-serif ;Color: #47b19f">Changes to Conditions of Use and
                                Invalidity</h3>
                            <p style="font-family:Poppins-Light, sans-serif"> These website Conditions of Use may be
                                changed by us at any time. You will be deemed to
                                accept the Conditions of Use (as amended) when you next use this site following any
                                amendment.</p>

                            <p style="font-family:Poppins-Light, sans-serif">If any provision of Conditions of Use is
                                held to be invalid by a court of competent
                                jurisdiction, such invalidity shall not affect the validity of the remaining provisions,
                                which shall remain in full force and effect.</p>
        `,
  }),
};
</script>

<style lang="scss">
.static-page {
  background: white;
  border-radius: 20px;
  padding-top: 20px;

  &__max-width {
    max-width: 700px;
    margin: auto;
  }
}

.m-20 {
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 5%;
}
</style>
